import React, { Component } from "react";
import {
  Layout,
  PortfolioNav,
  LazySpinner,
  PortfolioHeader,
} from "../../components";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import LazyLoad from "react-lazyload";
import locales from "../../constants";
import BanerMDD from "../../components/BanerMDD/BanerMDD";

class GMR extends Component {
  render() {
    const nav = {
      initialSlideIndex: 1,
    };
    const lang = "pl";
    const { slug } = this.props.pageContext;
    return (
      <Layout
        header={{
          background:
            "linear-gradient( 45deg, #325426, #447730, #558B3F, #447730, #325426 )",
          icons: "#64a34a",
          navClass: "gmr",
        }}
        seo={{
          title: "Green Mountain Resort",
          headerTitle: "gmr",
          href: slug,
          lang: "pl",
          ogImage: require("../../assets/img/portfolio/gmr_top_back.jpg"),
        }}
        langSwitch={{
          langKey: "en",
          langSlug: "/en/projects/green-mountain-resport/",
        }}
      >
        <PortfolioHeader name="gmr" height="252" />
        <section className="container-fluid gmr_section_2" id="info">
          <div className="row">
            <div className="col-md-6">
              <div className="inner">
                <h1>Green Mountain Resort</h1>
                <ul>
                  <li>Strona internetowa</li>
                  <li>Prezentacje</li>
                  <li>Katalogi</li>
                  <li>Kampania leadowa</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="inner">
                <p>
                  Deweloper należy do grupy Osada Śnieżka, w skład której
                  wchodzą powstałe kompleksy: Osada Śnieżka w Łomnicy, Osada
                  Śnieżka Medical Spa w Łomnicy, Blue Mountain Resort w
                  Szklarskiej Porębie, Platinum Mountain Resort w Szklarskiej
                  Porębie, Green Mountain Resort w Karpaczu oraz Crystal
                  Mountain Resort w Wiśle.
                </p>
                <p>
                  Są to wysokiej klasy kompleksy hotelowe działające w systemie
                  condo, gwarantujące stały zysk na wysokim poziomie stopy
                  zwrotu. Interesujący nas obiekt znajduje się w sercu
                  Karkonoszy i zawiera pokoje hotelowe oraz apartamenty, których
                  promocją zajmowała się nasza agencja.
                </p>
                <p>
                  Współpraca obejmowała wsparcie działań sprzedażowych
                  inwestycji Green Mountain Resort. Zakres współpracy obejmował;
                  opracowanie modelu funkcjonalnego serwisu internetowego wraz z
                  wykonaniem projektu oraz wdrożenia. Odpowiadaliśmy również za
                  sukces w zakresie przygotowania materiałów wspierających
                  sprzedaż (prezentacje, katalogi) oraz za stworzenie i
                  prowadzenie kampanii leadowej, którą wspieraliśmy przez
                  aktywne prowadzenie fanpage hotelu.
                </p>
                <p>Cel:</p>
                <ol>
                  <li>
                    Stworzyć narzędzia do prezentacji oferty (serwis
                    internetowy, prezentacje, katalogi)
                  </li>
                  <li>Zapewnić wsparcie sprzedaży</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid gmr_section_3">
          <div className="row">
            <div className="col-md-5 offset-md-1">
              <div className="inner">
                <h3>Serwis internetowy</h3>
                <p>
                  Strona internetowa{" "}
                  <a
                    href="https://green-mountain-resort.pl/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://green-mountain-resort.pl/
                  </a>{" "}
                  została zrealizowana dla dewelopera działającego na rynku
                  inwestycji aparthotelowych. Najważniejszym zadaniem, jakiemu
                  mieliśmy sprostać przy projekcie strony było stworzenie
                  unikalnych widoków graficznych, które pozwolą pokazać prestiż
                  inwestycji – hotelu o wysokim standardzie, położonego w jednym
                  z najpopularniejszych kurortów wypoczynkowych w Polsce, w
                  okolicach góry Śnieżki i sąsiedztwie Kurzych Skał,
                  stanowiących atrakcję dla miłośników wspinaczek górskich.
                </p>
              </div>
            </div>
            <div className="col-md-6 text-right">
              <div className="scroll_wrapper">
                <span />
                <div className="img_wrapper">
                  <img
                    src={require("../../assets/img/portfolio/gmr_entire_page.jpg")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid gmr_section_4">
          <div className="row no-gutters">
            <div className="col-md-5">
              <img
                className="img-fluid img_calc"
                src={require("../../assets/img/portfolio/gmr_main_2.png")}
                alt=""
              />
            </div>
            <div className="col-md-6 offset-md-1">
              <p>
                Chcieliśmy także skupić się na przedstawieniu bardzo bogatej
                oferty usługowo-rekreacyjnej, znajdującej się w hotelu.
              </p>
              <div className="row no-gutters">
                <div className="col-md-6">
                  <img
                    className="img-fluid"
                    src={require("../../assets/img/portfolio/gmr_main_3.png")}
                    alt=""
                  />
                </div>
                <div className="col-md-6">
                  <img
                    className="img-fluid img_right"
                    src={require("../../assets/img/portfolio/gmr_main_4.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid gmr_section_5">
          <div className="row">
            <div className="col-md-5 offset-md-3">
              <p>
                Projekt layoutu graficznego zdeterminowany był także
                maksymalizacją użyteczności strony oraz, co niezwykle ważne,
                realizacją celów biznesowych Klienta. Najważniejszą funkcją
                strony było pozyskiwanie leadów od potencjalnych
                klientów.Wszystkie formularze na stronie zostały połączone z
                zewnętrznym systemem do zautomatyzowanej obsługi zapytań.
              </p>
            </div>
          </div>
        </section>

        <section className="container-fluid gmr_section_6">
          <p>
            Oprócz projektu strony zajęliśmy się także zakodowaniem jej do w
            pełni działającej strony internetowej, do której zostały wpięte
            niezbędne narzędzia analityczne – m.in. Google Analytics czy Google
            Tag Manager. To umożliwiło śledzenie źródeł zapytań ofertowych
            zwiększając przy tym efektywność prowadzonej przez When kampanii
            marketingowej.
          </p>
          <LazyLoad once height={500} placeholder={<LazySpinner />}>
            <video
              width="100%"
              height="100%"
              loop
              autoPlay
              muted
              data-setup="{}"
            >
              <source
                src={require("../../assets/video_portfolio/GMR_video.webm")}
                type="video/webm"
              />
              <source
                src={require("../../assets/video_portfolio/GMR_video.mp4")}
                type="video/mp4"
              />
            </video>
          </LazyLoad>
        </section>

        <section className="container-fluid gmr_section_7">
          <div className="row">
            <div className="col-md-5 offset-md-1">
              <div className="inner">
                <h3>Kampanie leadowe</h3>
                <p>
                  Kampanię prowadziliśmy przez okres 1 roku. Pierwszy miesiąc
                  kampanii potraktowaliśmy jako testowy – analizowaliśmy dane
                  obrazujące zachowania użytkowników, realizację ustalonych
                  celów i poziom konwersji. Prowadziliśmy rozbudowane kampanie w
                  sieci wyszukiwania i reklamowej Google na całą Polskę aby
                  dotrzeć do osób, które mogą być zainteresowane kupnem
                  apartamentu w hotelu Green Mountain Resort. Dobraliśmy
                  odpowiednie słowa kluczowe związane z rynkiem inwestycyjnym
                  oraz portale tematyczne, na których pojawiały się kreacje
                  graficzne. Nasze kampanie śledziły przesłania formularzy
                  kontaktowych na stronie www oraz formularzy z prośbą o ofertę.
                  Równolegle prowadzono fanpage inwestycji oraz rozbudowane
                  kampanie na Facebooku – celem kampanii również było
                  pozyskiwanie leadów oraz budowanie w świadomości użytkowników
                  pożądanych obrazów dotyczących prestiżu inwestycji.
                  Przykładowe kreacje reklamowe poniżej:
                </p>
              </div>
            </div>
            <div className="col-md-6 text-right">
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/gmr_main_6.png")}
                alt=""
              />
            </div>
          </div>
        </section>

        <section
          className="container-fluid gmr_section_8"
          style={{ marginTop: 0 }}
        >
          <div className="row">
            <div className="col-md-6 text-right">
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/gmr_main_7.png")}
                alt=""
              />
            </div>
            <div className="col-md-4 offset-md-1">
              <div className="inner">
                <p>
                  Bazując na analizie potencjału kampanii zarekomendowaliśmy
                  ponad dwukrotne zwiększenie budżetu. To doprowadziło do
                  finalnego sukcesu – sprzedaży wszystkich apartamentów.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid gmr_section_9">
          <div className="row">
            <div className="col-md-5 offset-md-1">
              <h3>Materiały wspierające sprzedaż</h3>
              <p>
                Sprzedaż rozpoczyna się dopiero w momencie przekazania leadów
                naszemu Klientowi. Zadaniem agencji jest dostarczenie
                wartościowych kontaktów do potencjalnych inwestorów – to staje
                się podstawą dalszych działań po stronie dewelopera. Aby wspomóc
                Klienta w tym zadaniu przygotowaliśmy projekt prezentacji
                wspierającej sprzedaż – prezentacja w formie elektronicznej oraz
                drukowanej towarzyszyła Klientowi na spotkaniach biznesowych.
                Przygotowaliśmy obie prezentacje w trzech wersjach językowych –
                po polsku, niemiecku i angielsku.
              </p>
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/gmr_main_8.png")}
                alt=""
              />
            </div>
          </div>
        </section>

        <section className="container-fluid gmr_section_10">
          <div className="row">
            <div className="col-md-5 ">
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/gmr_main_9.png")}
                alt=""
              />
            </div>
            <div className="col-md-5">
              <p>
                Naszym zadaniem było stworzenie atrakcyjnego projektu
                wspierającego sprzedaż i prezentującego ofertę Green Mountain
                Resort. Projekt miał wywołać określone emocje i wzmóc pragnienie
                dokonania inwestycji, poprzez podkreślenie prestiżu miejsca i
                atrakcyjnych warunków współpracy. Prezentacja miała być spójna
                wizualnie z projektem strony internetowej – w ten sposób
                tworzymy spójny wizerunek Klienta, umacniamy komunikaty w
                świadomości odbiorców i tworzymy powiązania między wszystkimi
                elementami promocji. Poniższej przykładowe strony prezentacji.
              </p>
            </div>
          </div>
        </section>

        <section className="container-fluid gmr_section_11">
          <h3>Efekt:</h3>
          <p className="desc">
            Stworzyliśmy efektywne i spójne materiały prezentujące ofertę
            dewelopera. Przeprowadziliśmy skuteczną kampanię dzięki czemu
            sprzedane zostały wszystkie dostępne w inwestycji apartamenty -
            inwestorzy zdecydowali się na zakup 137 pokoi hotelowych oraz 130
            apartamentów. Zainteresowanie oferowanymi obiektami przełożyło się
            również na bezpośrednią sprzedaż kolejnej inwestycji dewelopera -
            Platinum Mountain Resort. Dzięki skutecznym działaniom zakres
            współpracy został rozszerzony na kolejne inwestycje dewelopera,
            które są wspierane przez nasze działania.
          </p>
          <div className="row custom_row" id="counter">
            <TrackVisibility offset={300} className="col-sm-4">
              {({ isVisible }) => (
                <>
                  <CountUp
                    className="counter-value"
                    start={0}
                    duration={3}
                    end={isVisible ? 130 : 0}
                  />
                  <p>sprzedanych apartamentów</p>
                </>
              )}
            </TrackVisibility>

            <TrackVisibility offset={300} className="col-sm-4">
              {({ isVisible }) => (
                <>
                  <CountUp
                    className="counter-value"
                    start={0}
                    duration={3}
                    end={isVisible ? 137 : 0}
                  />
                  <p>sprzedanych pokoi hotelowych</p>
                </>
              )}
            </TrackVisibility>
            <TrackVisibility offset={300} className="col-sm-4">
              {({ isVisible }) => (
                <>
                  <CountUp
                    className="counter-value"
                    start={0}
                    duration={3}
                    end={isVisible ? 100 : 0}
                  />
                  <p>procent wykonanych założeń</p>
                </>
              )}
            </TrackVisibility>
          </div>
          <hr />
          <h2>SUKCES!</h2>
        </section>

        <BanerMDD />
        <PortfolioNav data={nav} locales={locales[lang]} />
      </Layout>
    );
  }
}

export default GMR;
